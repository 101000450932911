import React, { useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import Context from '../../Context'

import './estilos.css';

import { ModalRegistro } from "../modalRegistro";
import { ModalLogin } from "../modalLogin";

export const Header = (props) => {
    const [burgerStyle, setBurgerStyle] = useState("none");
    const [fixedBurgerStyle, setFixedBurgerStyle] = useState("static");

    function toggleBurgerMenu() {
        if (burgerStyle === "none") {
            setBurgerStyle("flex")
            setFixedBurgerStyle("fixed")
        } else {
            setBurgerStyle("none")
            setFixedBurgerStyle("static")
        }
    }

    if (window.innerWidth > 1200 && burgerStyle === "flex") {
        setBurgerStyle("none")
    }

    const [showDesplegable, setShowDesplegable] = useState('none');
    function toggleDesplegable() {
        if (showDesplegable == 'none') {
            setShowDesplegable('flex')
        } else {
            setShowDesplegable('none')
        }
    }
    const [frontImage, setFrontImage] = useState('https://businessplus.es/assets/imgpruebas/avatar_default.png');
    const fetchNewImage = async () => {
        try {
            let axiosConfig = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
                },
            };
            await axios.get(`${process.env.REACT_APP_URL_API}usuarios/${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`)}`, axiosConfig)
                .then(result => {
                    console.log('Img-usuario::', result.data.data.imagenUsuario)
                    setFrontImage(result.data.data.imagenUsuario)
                })

        } catch (error) {
            console.log('error', error);
            setFrontImage('https://businessplus.es/assets/imgpruebas/avatar_default.png')
        }
    };
    useEffect(() => {
        fetchNewImage();
    }, [])

    console.log('ITEMS MENU::', props.data);
    return (
        <Context.Consumer>{({ modalRegistro, toggleModalRegisitro, modalLogin, toggleModalLogin, activateAuth, isAuth, removeAuth, updateEmailLogin, emailLogin }) => <>
         {
            !isAuth &&
            <>
                <ModalRegistro mostrarModal={modalRegistro} toggleModalRegisitro={toggleModalRegisitro} />
                <ModalLogin updateEmailLogin={updateEmailLogin} emailLogin={emailLogin}  mostrarModal={modalLogin} toggleModalLogin={toggleModalLogin} toggleModalRegisitro={toggleModalRegisitro} activateAuth={activateAuth} />
            </>
         }
            <div className="menu_header_contenedor">
                <nav className="menu_header">
                    <div class="logos">
                        <div className="menu_header_logo">
                            <a href="/">
                                <img src="https://uptawebinarscadiz.com/assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                        <div className="logo-diputacion">
                            <a href="/">
                                <img src="https://uptawebinarscadiz.com/assets/images/diputacion-cadiz.png" alt="diputación de Cadiz" />
                            </a>
                        </div>
                        <div className="logo-agenda">
                            <a href="/">
                                <img src="https://uptawebinarscadiz.com/assets/images/upta-agenda.png" alt="Agenda" />
                            </a>
                        </div>
                    </div>
                    <div class="menu">
                    <ul className="menu_header_items">
                        
                        {props.data.map(itemData =>
                            itemData.tipoZona == 'menu' && itemData.idZona == 2 && itemData.menuItems != null && itemData.menuItems.map(item => {
                                if (item.despliegaNivel === false) {
                                    return (
                                        <Fragment>
                                            <li className="item-menu"><NavLink exact={true} activeClassName='menu_active' to={`${item.enlace}`}>{item.nombre}</NavLink ></li>
                                        </Fragment>
                                    )
                                } else {
                                    return (
                                        <li className="item-menu"><NavLink exact={true} activeClassName='menu_active' to={`${item.enlace}`}>{item.nombre}</NavLink >
                                            <ul>
                                                {item.hijos.map(hijo =>
                                                    <NavLink exact={true} activeClassName='menu_active' to={`${hijo.enlace}`}>{hijo.nombre}</NavLink >
                                                )}
                                            </ul>
                                        </li>
                                    )
                                }
                            }
                            )
                        )}
                    </ul>

                    {
                        isAuth ?
                            <div>
                                <div className="menu_header_auth_users">
                                    <span><a onClick={() => removeAuth()}>Cerrar Sesión</a></span>
                                </div>
                            </div>
                            :
                            <div className="menu_header_users">
                                <button onClick={() => toggleModalLogin()}>Iniciar Sesión</button>
                                <button onClick={() => toggleModalRegisitro()}>Crear Cuenta</button>
                            </div>

                    }
                    </div>
                </nav>

                <nav className="menu_header_burger" style={{ position: fixedBurgerStyle }}>
                    <div className="menu_header_burger_logo">
                        <a href="/">
                            <img src="https://uptawebinarscadiz.com/assets/images/logo.png" alt="" />
                        </a>
                    </div>
                    <div className="menu_header_burger_burger" onClick={() => toggleBurgerMenu()}>|||</div>
                </nav>

                <nav className="b_menu_header" style={{ display: burgerStyle }}>
                    <ul className="b_menu_header_items">
                        {props.data.map(itemData =>
                            itemData.tipoZona == 'menu' && itemData.idZona == 2 && itemData.menuItems != null && itemData.menuItems.map(item => {
                                if (!item.despliegaNivel) {
                                    return (
                                        <Fragment>
                                            <li className="item-menu"><NavLink exact={true} activeClassName='menu_active' to={`${item.enlace}`}>{item.nombre}</NavLink ></li>
                                        </Fragment>
                                    )
                                } else {
                                    return (
                                        <Fragment>
                                            <div className="menu_separador"></div>
                                            {item.hijos.map(hijo =>
                                                <NavLink exact={true} activeClassName='menu_active' to={`${hijo.enlace}`}>{hijo.nombre}</NavLink >
                                            )}
                                            <div className="menu_separador"></div>
                                        </Fragment>
                                    )
                                }
                            }
                            )
                        )}
                    </ul>
                    {
                        isAuth ?
                            <div className="b_menu_header_auth">
                                <div className="b_menu_header_auth_users">
                                    <span><a onClick={() => removeAuth()}>Cerrar Sesión</a></span>
                                </div>
                            </div>
                            :
                            <div className="b_menu_header_users">
                                <button onClick={() => toggleModalRegisitro()}>Crear Cuenta nueva</button>
                                <button onClick={() => toggleModalLogin()}>Iniciar Sesión</button>
                            </div>

                    }
                </nav>
            </div>
        </>}
        </Context.Consumer>
    )
}