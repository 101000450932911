import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { CargaContenido } from "../../componentes/cargaContenido";
import { Header } from "../../componentes/header";
import { BannerPrincipal } from "../../componentes/bannerPrincipal";
import { SliderCanales } from "../../componentes/sliderCanales";
import { GridCanales } from "../../componentes/gridCanales";
import { CajaTexto } from "../../componentes/cajaTexto";
import { CajaInfo } from "../../componentes/cajaInfo";
import { Footer } from "../../componentes/footer";
import { ScrollToTop } from '../../componentes/scrollToTop';

export const ListaCanales = (props) => {



  console.log('DATOS::', props.zonasContenido)

  if (!props.zonasContenido) { return (<div></div>) }

  return (
    <>
      {
        props.seoContenido !== null &&
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={props.seoContenido.seoDescription} />
          <title>{props.seoContenido.seoTitle}</title>
          <meta property="og:title" content={props.seoContenido.seoTitle} />
          <meta property="og:description" content={props.seoContenido.seoDescription} />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="Business+"></meta>
          <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
          <meta property="og:image" content={props.seoContenido.seoImage} />
          <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
          <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
          <meta name="twitter:title" content={props.seoContenido.seoTitle} />
          <meta name="twitter:description" content={props.seoContenido.seoDescription} />
          <meta name="twitter:image" content={props.seoContenido.seoImage} />
        </Helmet>
      }
      <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />



      <div className="section bg-gradientDark">
        <div className="container">
          <div className="margin-tb-30">
            {

              props.zonasContenido.map(item =>
                <Fragment>
                  {item.idZona === 3 &&
                    <CargaContenido item={item} tipo={item.tipoZona} />
                  }
                </Fragment>
              )

            }

          </div>
        </div>
      </div>

      <div className="section bg-gradientDark">
        <div className="container">
          {

            props.zonasContenido.map(item =>
              <Fragment>

                {item.idZona === 4 &&
                  <CargaContenido item={item} tipo={item.tipoZona} />
                }

              </Fragment>
            )

          }

        </div>
      </div>

      <div className="section bg-gradientDarkFlip">
        <div className="container">
          <div className="row v-center">
            <div className="col-2">
              <CajaTexto>
                {

                  props.zonasContenido.map(item =>
                    <Fragment>
                      {item.idZona === 5 &&
                        <CargaContenido item={item} tipo={item.tipoZona} />
                      }

                    </Fragment>
                  )

                }

              </CajaTexto>
            </div>
            <div className="col-2">
              <CajaTexto>
                {

                  props.zonasContenido.map(item =>
                    <Fragment>
                      {item.idZona === 6 &&
                        <CargaContenido item={item} tipo={item.tipoZona} />
                      }
                    </Fragment>
                  )

                }

              </CajaTexto>
            </div>
          </div>
        </div>
      </div>

      <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}