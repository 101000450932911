import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Header } from "../../../componentes/header";
import { ContenidoMisInscripciones } from "../../../componentes/usuario/contenidoMisInscripciones";
import { MenuCuentaUsuario } from "../../../componentes/menuCuentaUsuario"
import { Footer } from "../../../componentes/footer";
import { ScrollToTop } from '../../../componentes/scrollToTop';

export const MisInscripciones = (props) => {

    const [listaEventos, setListaEventos] = useState(false)
    const fetchListaEventos = async () => {
        let dataFormEventos = {
            "formato": "home_publica",

        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            },
        };
        axios.post(`${process.env.REACT_APP_URL_API}formatos/obtenerformatopublico`, dataFormEventos, axiosConfig)
            .then((res) => {

                setListaEventos(res.data.data)
                console.log('DATA HOME::', res.data.data)

            })
            .catch((err) => {
                console.log('ERROR:', err)
                // props.removeAuth()
            })
    }

    useEffect(() => {
        fetchListaEventos();
    }, [])

    if (!listaEventos) { return (<div></div>) }

    return (
        <>
            <Header logo={listaEventos[1].texto} data={listaEventos} />
            <div className="cuenta-usuario  bg-gradientDark">
                <div className="container">
                    <div className="cabecera-cuenta-usuario">
                        <div className="titulo">Hola <span>{localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`)}</span></div>
                        <div className="subtitulo">Bienvenido a tu perfil</div>
                    </div>
                </div>
            </div>
            <div className="section cuenta-usuario_contentbox">
                <MenuCuentaUsuario removeAuth={props.removeAuth} />
                <ContenidoMisInscripciones />
            </div>

            <Footer data={props.zonasContenido} />
            <ScrollToTop />
        </>

    )

}