import React, { useState } from 'react'
import axios from 'axios';
import { AiOutlineEye } from "react-icons/ai";
import './estilos.css';

export const CajaItemEventoPresencial = (props) => {
    console.log('props presencial:::', props)

    return (
        <div className={`cardEvento base ${props.clase}`}  >
                <div className="cardEvento-contendor-imagen">
                    <div className="cardEvento-imagen">
                        
                        <a href={props.enlace}><img src={props.imagen} /></a>
                    </div>
                </div>
                <div className="cardEvento-body">
                    <div className="cardEvento-titulo">
                        
                        <a href={props.enlace}>{props.evento.nombre}</a>
                    </div>
                   
                    <div className="emision">  
                                    {props.evento.precio == 'Tutorial' ?
                                        <>
                                            
                                            <div className="emision-hour">
                                                <div className="nombre">{props.evento.duracion}</div>
                                                <div className="info" dangerouslySetInnerHTML={{__html: props.evento.precio}} />
                                             </div>
                                        </>
                                    :
                                    props.evento.urlPlayer != null ?
                                    <>
                                           
                                            <div className="emision-hour">
                                                <div className="nombre">{props.evento.duracion}</div>
                                                <div className="info" dangerouslySetInnerHTML={{__html: props.evento.precio}} />
                                             </div>
                                    </>
                                    :
                                    <>
                                        <div className="emision-day">
                                            <span className="float-left">{new Date(props.evento.fechaEvento).getDate()}</span> 
                                            <span className="emision-month">{new Date(props.evento.fechaEvento).toLocaleString('es-Es', { month: 'short' })}</span>
                                        </div>
                                        <div className="emision-hour">
                                                <div className="nombre">{props.evento.duracion}</div>
                                                <div className="info" dangerouslySetInnerHTML={{__html: props.evento.precio}} />
                                        </div>
                                    </> 
                                    }
                                     
                                    
                                </div>
                </div>
                <div className="cardEvento-footer"> 
                    
                    <div className="btn principal"><a href={props.enlace}>Ver ahora</a></div>         
                </div>
            </div>
    )
}