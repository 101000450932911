import React, { Component, useState, useContext, useRef, useEffect } from 'react';
import videojs from 'video.js'
import axios from 'axios';
import Script from 'react-load-script'
import GuardarLogContext from '../../ContextLog'
import GuardarLogPlayerContext from '../../ContextLogPlayer'
import { FaRegPlayCircle } from "react-icons/fa";
import { BsFillCaretRightFill }  from "react-icons/bs";
import './estilos.css';

export const VideoPlayer = (props) => {

  const [tiempovisionado, setTiempovisionado] = useState(0)
  const [mostrarContinuar, setmostrarContinuar] = useState(true)
  const [endedVideo, setEndedVideo] = useState(false)

  const getVisualizacion = async () => {
    let url = `${process.env.REACT_APP_URL_API}eventos/MostarVisualizacionesEvento`
    let dataFormEventos = {
      "idEvento": props.idEvento,
      "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
    }
    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
      },
    };
    axios.post(url, dataFormEventos, axiosConfig)
      .then((res) => {

        //setdatos(res.data.data)
        console.log('get DATA VISUALIZA::', res.data.data)
        setTiempovisionado(res.data.data.horaComienzo)
      })
      .catch((err) => {
        console.log('ERROR:', err)
        // props.removeAuth()
      })
  }

  const grabarDatosVisualizacion = async (idEvento, tiempo, isEnded) => {
    console.log("isEnded", isEnded);
    console.log('VISUALIZA grabar tiempo::', tiempo)
    let url = `${process.env.REACT_APP_URL_API}eventos/Visualizacionesevento`
    let dataFormEventos = {
      "idEvento": idEvento,
      "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
      "horacomienzo": Math.round(tiempo),
      "visualizado": isEnded
    }
    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
      },
    };
    axios.post(url, dataFormEventos, axiosConfig)
      .then((res) => {

        //setdatos(res.data.data)
        console.log('set DATA VISUALIZA::', res.data.data)

      })
      .catch((err) => {
        console.log('ERROR:', err)
        // props.removeAuth()
      })
  }

  useEffect(() => {
    getVisualizacion();
  }, [])

  const MINUTE_MS = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      const player2 = videojs(videoPlayerRef.current, videoJSOptions, () => {

        if (player2.currentTime() !== 0) {
          console.log(player2.currentTime())
          grabarDatosVisualizacion(props.idEvento, player2.currentTime(), false)
        }
      }, [])


    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])


  const { videoSrc } = props.urlSource;
  const videoPlayerRef = useRef();
  const videoJSOptions = {
    autoplay: false,
    aspectRatio: '16:9',
    controls: true,
    sources: [
      {
        src: props.urlSource,
        type: props.tipo
      },
    ],
  }

  const continuarVideo = (tiempo) => {
    console.log('continuarVideo VISUALIZA', tiempo)
    console.log('continuarVideo VISUALIZA', tiempovisionado)
    const player = videojs(videoPlayerRef.current, videoJSOptions, () => {
      player.currentTime(tiempo)
      setmostrarContinuar(false)
      player.play()
      return () => {
        if (player) {
          player.dispose();
        }
      }
    }, [])
  }

  useEffect(() => {
    const player = videojs(videoPlayerRef.current, videoJSOptions, () => {
      player.src(videoSrc)
      console.log('Player Ready')
      player.on('play', () => {
        console.log('play', player.currentTime())
        HandleClickPlay('play', player.currentTime(), false)
      });
      player.on('pause', () => {
        console.log('pause', player.currentTime())
        HandleClick('pause', player.currentTime(), false)
      });
      player.on('ended', () => {
        console.log('end', player.currentTime())
        HandleClick('end', player.currentTime(), true)
        setEndedVideo(true)
        setmostrarContinuar(true)
      });
      player.on('seeked', () => {
        console.log('seeked', player.currentTime())
        HandleClick('seeked', player.currentTime(), false)
      });
    })

    return () => {
      if (player) {
        player.dispose();
        console.log('Player Disposed')
      } else {
        console.log('Player NO Disposed')
      }
    }
  }, [])

  let isBajoDemanda = false;
  if (props.isBajodemanda == 'true') {
    isBajoDemanda = true
  }

  const log = useContext(GuardarLogContext)
  const logPlayer = useContext(GuardarLogPlayerContext)
  const HandleClickPlay = (accion, tiempo) => {
    console.log('ENTRA EN HANDELCLICK VISUALIZA')
    logPlayer.guardarLogPlayer(props.idEvento, accion, tiempo)
    log.logEvento(props.idEvento, isBajoDemanda, accion, tiempo)

  }
  const HandleClick = (accion, tiempo, isEnded) => {
    console.log('ENTRA EN HANDELCLICK VISUALIZA')
    grabarDatosVisualizacion(props.idEvento, tiempo, isEnded)
    logPlayer.guardarLogPlayer(props.idEvento, accion, tiempo)
    log.logEvento(props.idEvento, isBajoDemanda, accion, tiempo)

  }

  return (
    <div className="imagenEvento">
      <div className="container-img">
        {
          props.directo ?
            <div className="anuncio_directo">
              <img src="https://businessplus.es/assets/imgpruebas/anuncio_emision_logo.svg" alt="icono de directo" />
              <p>En Directo</p>
            </div>
            : null
        }

        {
          (tiempovisionado > 0 && !props.directo) ?
            <div className={mostrarContinuar > 0 ? "video-resume active" : "video-resume"}>
              <div className="btn-resume" onClick={() => { continuarVideo(tiempovisionado) }}>Continuar</div>
              <div className="btn-resume2" onClick={() => { continuarVideo(0) }}>Ver desde el principio</div>
            </div>
            :
            endedVideo ?
              <div className={mostrarContinuar > 0 ? "play_btn" : "play_btn_hide"} onClick={() => { continuarVideo(0) }}>
                <div className="btn-resume2" onClick={() => { continuarVideo(0) }}>Ver desde el principio</div>
              </div>
              :
              <div className={mostrarContinuar > 0 ? "play_btn" : "play_btn_hide"} onClick={() => { continuarVideo(0) }}>
                <div className='btn-play-bg'> <BsFillCaretRightFill /> </div>
              </div>
        }
        <video ref={videoPlayerRef} className='video-js' />

      </div>
    </div>
  )
}