import { createContext } from 'react';
import axios from 'axios';

const GuardarLogPlayerContext = createContext({
  guardarLogPlayer: (idEvento, accion, tiempo) => {
    console.log('Log video evento::', idEvento)
    console.log('Log video accion::', accion)
    console.log('Log video tiempo::', tiempo)

    let dataFormlog = {
      "idEvento": idEvento,
      "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
      "horacomienzo": tiempo,
      "visualizado": accion
    }

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
      },
    };

    axios.post(`${process.env.REACT_APP_URL_API}eventos/Visualizacionesevento`, dataFormlog, axiosConfig)
      .then((res) => {
        console.log('Log::', res.data)
      })
      .catch((err) => {
        console.log('Log ERROR::', err)
      })
  }
});

export default GuardarLogPlayerContext;