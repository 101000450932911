import { createContext } from 'react';
import axios from 'axios';

const GuardarLogContext = createContext({
  logEvento: (idEvento, esBajoDemanda, accion, valorAccion) => {
    // console.log('##### grabar log::')
    // console.log('log usuario:: ', localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`))
    // console.log('log idevento:: ', idEvento)
    // console.log('log esbajodemanda:: ', esBajoDemanda)
    // console.log('log accion:: ', accion)
    // console.log('log AccionValor:: ', valorAccion)
    // console.log('##### end grabar log:: ')
    // console.log('grabar log http://api.ipify.org/?format=json')


    axios.get(`https://webinars.spinmedia.es/ip.php`)
      .then((dataip) => {

        console.log('ip::', dataip.data.ip)
        let dataFormlog = {
          "claveUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-id`),
          "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
          "idEvento": idEvento,
          "ip": dataip.data.ip,
          "BajoDemanda": esBajoDemanda,
          "Navegador": `${window.navigator.userAgent}`,
          "accion": accion,
          "accionValor": valorAccion
        }
        let axiosConfig = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
          },
        };
        console.log('LOG::', dataFormlog)
        axios.post(`${process.env.REACT_APP_URL_API}eventos/GrabarEventoLogs`, dataFormlog, axiosConfig)
          .then((res) => {

            console.log('Log::', res.data)


          })
          .catch((err) => {
            console.log('Log ERROR::', err)
          })


      })
      .catch((err) => {
        console.log('Log ERROR IP::', err)
      })
  }
});

export default GuardarLogContext;