import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Header } from "../../componentes/header";
import { Footer } from "../../componentes/footer";
import { ScrollToTop } from '../../componentes/scrollToTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2'
import './estilos.css';

export const Contacto = (props) => {
  console.log('en contacto::')
  const [listaEventos, setListaEventos] = useState(false)


  const recaptchaRef = React.createRef();
    const [aceptCaptha, setAceptCaptha] = useState(false);
    const onChange = (value) => {
        setAceptCaptha(true)
      }

    const [messageLogin, setmessageLogin] = useState('');
    const messageLoginChange = (val) => {
        setmessageLogin(val)
    }

    const [formIsInvalid, setformIsInvalid] = useState(false);
    const formIsInvalidChange = (val) => {
        setformIsInvalid(val)
    }
    
    const [nombreData, setNombreData] = useState(localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`));
    const nombreChange = (val) => {
        setNombreData(val.target.value)
    }

    const [emailData, setEmailData] = useState(localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-email`));
    const emailChange = (val) => {
        setEmailData(val.target.value)
    }

    const [asuntoData, setAsuntoData] = useState(null);
    const asuntoChange = (val) => {
        setAsuntoData(val.target.value)
    }

    const [mensajeData, setMensajeData] = useState(null);
    const mensajeChange = (val) => {
        setMensajeData(val.target.value)
    }


  const fetchListaEventos = async () => {
    let dataFormEventos = {
      "formato": "home_publica",

    }

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',

      },
    };
    axios.post(`${process.env.REACT_APP_URL_API}formatos/obtenerformatopublico`, dataFormEventos, axiosConfig)
      .then((res) => {

        setListaEventos(res.data.data)
        console.log('DATA HOME::', res.data.data)

      })
      .catch((err) => {
        console.log('ERROR:', err)
        // props.removeAuth()
      })
  }

  useEffect(() => {
    fetchListaEventos();
  }, [])


  const sendContacto = async () => {

    try {
        var dataForm = {
            "nombre":nombreData,
            "email": emailData,
            "asunto": asuntoData,
            "mensaje": mensajeData,
        }
        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },  
          };
        console.log('go to axios')
        await axios.post(`${process.env.REACT_APP_URL_API}formatos/EnviarFormularioContacto`, dataForm, axiosConfig)
        .then(resLogin => {
            console.log('resLogin',resLogin.data)
            if(resLogin.status === 200){
                if(resLogin.data === 'error'){
                    console.log('err2')
                    messageLoginChange('El formulario contiene errores')
                }else{
                    formIsInvalidChange(false)
                      Swal.fire({
                        title: '',
                        text: `Mensaje enviado`,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#42A72C',
                        cancelButtonColor: '#E5324B', 
                        confirmButtonText: 'Aceptar',
                        cancelButtonText: 'Cancelar'
                     }).then((result) => {
                        if(result.value){
                            
                       }
                     })
               
                }
            }else{
                console.log('err')
                messageLoginChange('El formulario contiene errores')
            }

        })
          
        } catch (error) {
            //setloaddata(false);
            console.log(error)
            messageLoginChange('El formulario contiene errores') 
            formIsInvalidChange(true)
        }
    }   

  if (!listaEventos) { return (<div></div>) }

  return (
    <>
      <Header logo={listaEventos[1].texto} data={listaEventos} />
      <div className='banner-contacto'>
        <div class="bannerContacto">
            <div class="bannerContacto_background">
                <img src="https://back.uptawebinarscadiz.com/almacen/home_publica_1/union_2.jpg" />
            </div>
            <div class="bannerContacto_container">
                <div class="claim-header">
                    <div class="content">
                        <div class="titulo">
                            Contáctanos
                        </div>
                        <div class="subtitulo">
                            En UPTA estamos a tu disposición.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="section bg-gradientFooter">
        <div class="container">
            <div className='contacto-container'>
                <div className='col1'>
                    <div className='logos'>
                        <div className='logo1'>
                        <img src="https://uptawebinarscadiz.com/assets/images/logo.png" alt="logo" />
                        </div>
                    </div>
                    <h2>Contacto</h2>
                    <h3>¿Tienes preguntas o necesitas ayuda?</h3>
                    <p>
                        Escríbenos, te asesoramos en todo lo que necesites. 
                    </p>
                    <p>
                        Si tienes alguna <b>incidencia técnica</b> o problemas para acceder al contenido, escríbenos a: <a href="mailto:dpd@upta.es">dpd@upta.es</a>
                    </p>
                    <div className='redes'>
                        <h2>Síguenos <span className='lead'>en las redes</span></h2>
                        <div className='item-redes'>
                            <a className='enlaceredes' href="https://twitter.com/upta_es" target="_blank">
                                <div className='icon'><FontAwesomeIcon icon={faTwitter} /></div>
                                <div className='nombre'>Twitter</div>
                            </a>
                        </div>
                        <div className='item-redes'>
                            <a className='enlaceredes' href="https://www.facebook.com/UptaEs/" target="_blank">
                                <div className='icon'><FontAwesomeIcon icon={faFacebookF} /></div>
                                <div className='nombre'>Facebook</div>
                            </a>
                        </div>
                        <div className='item-redes'>
                            <a className='enlaceredes' href="https://www.youtube.com/user/UPTAes/featured" target="_blank">
                                <div className='icon'><FontAwesomeIcon icon={faYoutube} /></div>
                                <div className='nombre'>Youtube</div>
                            </a>
                        </div>
                        <div className='item-redes'>
                            <a className='enlaceredes' href="https://www.instagram.com/uptaespana/ " target="_blank">
                                <div className='icon'><FontAwesomeIcon icon={faInstagram} /></div>
                                <div className='nombre'>Instagram</div>
                            </a>
                        </div>
                        <div className='item-redes'>
                            <a className='enlaceredes' href="https://www.linkedin.com/in/upta-espa%C3%B1a-92701a143/" target="_blank">
                                <div className='icon'><FontAwesomeIcon icon={faLinkedin} /></div>
                                <div className='nombre'>Linkedin</div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='col2 formulario-contacto'>
                    <img className='imgcontacto' src="https://uptawebinarscadiz.com/assets/images/imagen-comtacto.jpg" alt="contacto" />
                    
                   
                </div>
            </div>
        </div>
      </div>
      <Footer data={props.zonasContenido} />
      <ScrollToTop />
    </>

  )

}