
import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";

import './estilos.css';

export const Listadopreguntas = (props) => {

    const [question, setQuestion] = useState(null);
    const questionChange = (val) => {
        setQuestion(val.target.value)
    }

    const cancelData = async () => {
        setQuestion(null);
        //document.getElementById("isdivs").className = ''
    }

    const guardarPregunta = async (hilo) => {
        let urlApi = `${process.env.REACT_APP_URL_API}hilos/AltaPost`


        let dataFormEventos = {

            "idHilo": hilo,
            "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
            "idPostPadre": 0,
            "nombreUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-nombre`),
            "texto": question
        }
        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`

            },
        };

        console.log('enviar pregunta1', dataFormEventos)
        if (question != null) {
            console.log('enviar pregunta2', dataFormEventos)
            axios.post(urlApi, dataFormEventos, axiosConfig)
                .then((res) => {
                    //if(props.isAuth){
                    //    let BajoDemanda = false
                    //    if(res.data.data[0].eventos[0].idTipoEvento == 1){BajoDemanda = true} 
                    //    props.logEvento(res.data.data[0].eventos[0].idEvento,BajoDemanda,'visualiza-evento','true')
                    //}
                    setQuestion(null)
                    console.log('res.data.data chat::', res.data.data)
                    setdataChat(res.data.data)
                    //const grouped = groupBy(res.data.data, item => item.idZona);

                })
                .catch((err) => {
                    console.log('ERROR', err)
                    //  props.isAuth && props.removeAuth()

                })
        }

    }

    const [dataChat, setdataChat] = useState(false)

    const fetchDataChat = async () => {
        let urlApi = `${process.env.REACT_APP_URL_API}hilos/PostUsuariosEvento`

        let dataFormEventos = {
            "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
            "IdEvento": props.idEvento

        }
        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`

            },
        };

        console.log('dataFormEventos', dataFormEventos)
        axios.post(urlApi, dataFormEventos, axiosConfig)
            .then((res) => {


                setdataChat(res.data.data)
                console.log("DATA CHAT::", res.data.data)
                //const grouped = groupBy(res.data.data, item => item.idZona);

            })
            .catch((err) => {
                console.log('ERROR chat', err)
                //  props.isAuth && props.removeAuth()

            })
    }

    useEffect(() => {
        fetchDataChat();
    }, [])
    const [ticking, setTicking] = useState(true),
        [count, setCount] = useState(0)
    useEffect(() => {
        const timer = setInterval(() => ticking && fetchDataChat(), 30000)
        return () => clearInterval(timer)
    })
    if (typeof dataChat == 'number' || !dataChat) {
        fetchDataChat(); return (
            <Fragment>
                <div className="listadopreguntas caja">
                    <div className="titulo"><h2>Chat del evento</h2></div>
                    {console.log('PREGUNTAS CHAT::', props.item)}
                    <div className="cajaChat">

                    </div>

                    <div id='isdivs'>
                        <div className="rowpreguntar">
                            <textarea className="textareaPreguntas" placeholder="Escribe tu mensaje aquí…" id="message" name="message" rows="5" cols="30" onChange={questionChange}>

                            </textarea>
                            <div className="botoneraPreguntas">
                                <button className="btn btn-borrar" onClick={cancelData}>Cancelar</button>
                                <button className="btn btn-enviar" onClick={guardarPregunta(props.item.valorWidget)} >Enviar</button>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
    return (
        <Fragment>
            <div className="listadopreguntas caja">
                <div className="titulo"><h2>Chat del evento</h2></div>

                <div className="cajaChat">
                    {dataChat.map(item =>

                        <div className={`preguntachat ${item.idPostPadre !== 0 ? " derecha" : "preguntachat"} ${item.publico ? " publico" : ""} `}>{console.log('ITEMM::', item)}
                            <div className="globo">
                                <div className="nombre">{item.nombreUsuario}</div>
                                <div className="texto"><div dangerouslySetInnerHTML={{ __html: item.texto }} /></div>
                            </div>
                            <div className="hora">{new Date(item.fechaCreacion).toLocaleString('es-Es', { hour: '2-digit', minute: '2-digit' })}</div>
                        </div>
                    )}
                </div>


                <div id='isdivs'>
                    <div className="rowpreguntar">
                        <textarea className="textareaPreguntas" placeholder="Escribe tu mensaje aquí…" id="message" name="message" rows="5" cols="30" onChange={questionChange}>

                        </textarea>
                        <div className="botoneraPreguntas">
                            <button className="btn btn-borrar" onClick={cancelData}>Cancelar</button>
                            <button className="btn btn-enviar" onClick={() => guardarPregunta(props.item.valorWidget)}  >Enviar</button>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}
