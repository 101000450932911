import React, { Fragment } from 'react';

import { CargaContenido } from "../../componentes/cargaContenido";
import { Header } from "../../componentes/header/index";
import { Footer } from "../../componentes/footer";
import { ScrollToTop } from '../../componentes/scrollToTop';
import { Helmet } from "react-helmet";
import './estilos.css';

export const HtmlEstatico = (props) => {
    return (
        <>
            {
                props.seoContenido !== null &&
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={props.seoContenido.seoDescription} />
                    <title>{props.seoContenido.seoTitle}</title>
                    <meta property="og:title" content={props.seoContenido.seoTitle} />
                    <meta property="og:description" content={props.seoContenido.seoDescription} />
                    <meta property="og:locale" content="es_ES" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:site_name" content="Business+"></meta>
                    <meta property="article:modified_time" content={props.seoContenido.fechaModificacion}></meta>
                    <meta property="og:image" content={props.seoContenido.seoImage} />
                    <meta property="og:image:width" content={props.seoContenido.seoImageWidth} />
                    <meta property="og:image:height" content={props.seoContenido.seoImageHeight} />
                    <meta name="twitter:title" content={props.seoContenido.seoTitle} />
                    <meta name="twitter:description" content={props.seoContenido.seoDescription} />
                    <meta name="twitter:image" content={props.seoContenido.seoImage} />
                </Helmet>
            }
            <div className="html-body">
                <Header logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
                <div className="html-body_content">
                    {
                        props.zonasContenido.map(item =>
                            <Fragment>
                                {item.idZona === 3 &&
                                    <CargaContenido item={item} tipo={item.tipoZona} />
                                }
                            </Fragment>
                        )
                    }
                    {
                        props.zonasContenido.map(item =>
                            <Fragment>
                                {item.idZona === 4 &&
                                    <CargaContenido item={item} tipo={item.tipoZona} />
                                }
                            </Fragment>
                        )
                    }
                </div>
                <Footer logo={props.zonasContenido[0].texto} data={props.zonasContenido} />
                <ScrollToTop />
            </div>
        </>
    )
}