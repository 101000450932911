import React, { useState, useRef } from 'react';
import './estilos.css'; // Tell webpack that Button.js uses these styles

export const ModalActivacion = (props) => {
  const cerrarModalActivacion = () => {
    props.setMostrarModalActivacion(false)
    window.location.reload()
  }
  return (
    <div className={`baseModal ${props.mostrarModalActivacion ? 'open' : ''}`}>
      <div className="modalBody modalXbody">
        <p className="modalRegistro_cerrar" onClick={() => cerrarModalActivacion()}>cerrar x</p>
        <div className="tituloheader"></div>
        <div className="row modalX" >
          <div className="titulo">{props.msgActivacion1}</div>
          <p>{props.msgActivacion2}</p>
          {
            props.msgActivacion3 ?
              <p>{props.msgActivacion3}</p>
              : null
          }

          <div className="btn transparente" onClick={() => cerrarModalActivacion()}>Cerrar</div>
        </div >
      </div>
    </div>
  )
}