import React, { Fragment, useState } from 'react';
import { CargaContenido } from "../cargaContenido";
import './estilos.css';

export const Footer = (props) => {
    console.log('footer',props.data)
    if (props.data == undefined) {
        return (
            <div className="section sectionFooter">
                <div className="container">
                    <div className="footer">
                        <div class="logos-footer">
                            <a class="navbar-brand" href="https://www.juntadeandalucia.es/" target="_blank"><img src="https://uptawebinarscadiz.com/assets/images/diputacion-cadiz.png" alt="diputación de Cadiz" /></a>
                            <a class="navbar-brand" href="https://www.upta-andalucia.org/" target="_blank"><img src="https://uptawebinarscadiz.com/assets/images/upta-agenda.png" alt="Agenda" /></a>
                        </div>
                        <div className="copyfooter">
                            <p>© UPTA Unión de Profesionales y Trabajadores Autónomos</p>
                        </div>
                        <ul className="menuFooter">
                            <li className="itemMenuFooter"><a href="/politica-de-privacidad/">Política de privacidad.</a></li>
                            <li className="itemMenuFooter"><a href="/politica-de-cookies/">Política de cookies</a></li>
                            <li className="itemMenuFooter"><a href="/aviso-legal/">Aviso legal</a></li>

                        </ul>
                        
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="section sectionFooter"> 
            <div className="container">
                <div className="footer">
                     <div class="logos-footer">
                        <div className="logo-diputacion">
                                <a href="/">
                                    <img src="https://uptawebinarscadiz.com/assets/images/diputacion-cadiz.png" alt="diputación de Cadiz" />
                                </a>
                            </div>
                            <div className="logo-agenda">
                                <a href="/">
                                    <img src="https://uptawebinarscadiz.com/assets/images/upta-agenda.png" alt="Agenda" />
                                </a>
                            </div>
                    </div>
                    <div className="copyfooter">
                        <p>© UPTA Unión de Profesionales y Trabajadores Autónomos</p>
                    </div>
                    <ul className="menuFooter">
                        {props.data.map(itemData =>
                            itemData.tipoZona == 'menu' && itemData.titulo == "Menú Pie" && itemData.menuItems != null && itemData.menuItems.map(item => {
                                return (
                                    <li className="itemMenuFooter"><a href={item.enlace}>{item.titulo}</a></li>
                                )
                            }
                            )
                        )}
                        <div className="menuFooter_rrss">
                            <div class="redes">
                                {
                                props.data.map(item =>
                                    <Fragment>
                                        {item.idZona === 6 &&
                                        <CargaContenido item={item} tipo={item.tipoZona} />
                                        }
                                    </Fragment>
                                )
                                }
                            </div>
                        </div>
                    </ul>
                    
                </div>
            </div>
        </div>
    )
}