import React, { useState, useRef, Fragment } from 'react';
import axios from 'axios';
import { Botonbase } from "../botonbase";
import { ModalRegistroInscripcion } from "../modalRegistroInscripcion";
import { ModalRegistroInscripcionPresencial } from "../modalRegistroInscripcionPresencial";
import './estilos.css'; 

export const ModalInscripcionPresencial = (props) => {
  console.log('props.dataEvento',props.dataEvento)
  const [formIsInvalid, setformIsInvalid] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [errorExisteMail, setErrorExisteMail] = useState(false);
  const [existeMail, setExisteMail] = useState(false);
  const [valorEmail, setValorEmail] = useState('');
  const [messageLogin, setmessageLogin] = useState('');

  const validaPasoUno = async () => {
    var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    let dataForm = {
      "email": valorEmail.toLowerCase(),
    }
    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }

    await axios.post(`${process.env.REACT_APP_URL_API}auth/ExisteUsuarioRegistro`, dataForm, axiosConfig)
    .then((res) => {
      console.log(res)
      setExisteMail(false)
      if (!regex.test(valorEmail)) {
        setErrorMail(true)
      }else{
        //console.log('nuevo usuario')
        props.closeModal(false)
        setShowModalRegistroPresencial(true)
      }
    })
    .catch((err) => {
      console.log(err)
      setExisteMail(true)
      setErrorExisteMail(true)
      props.updateEmailLogin(valorEmail.toLowerCase())
      //mostrar formulario de login con el valor del mail
      props.closeModal(false)
      props.toggleModalLogin()
    })

   
  
  }

  const emailChange = (val) => {
    setValorEmail(val.target.value)
    setErrorMail(false)
    setErrorExisteMail(false)
  }
  let fechaEvento = props.dataEvento.fechaEvento;
    let year = fechaEvento.split("-")[0];
    let month = fechaEvento.split("-")[1];
    if (month.substr(0, 1) == "0") {
        month = month.substr(1);
    }
    month = month - 1;
    let day = fechaEvento.split("-")[2].split("T")[0];
    let monthNames = ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ag", "Sept", "Oct", "Nov", "Dic"];
    let monthName = monthNames[month];

    if (day < 10) {
        day = day.split('0')[1]
    }
  const [showModalRegistro, setShowModalRegistro] = useState(false);
  const [showModalRegistroPresencial, setShowModalRegistroPresencial] = useState(false);

  const abreRegistro = () => {
    props.closeModal(false)
    setShowModalRegistro(true)
  }
  const abrelogin = () => {
    props.closeModal(false)
    props.toggleModalLogin()
  }

  return (
    <Fragment>
      <ModalRegistroInscripcion mostrarModal={showModalRegistro} setMostrarModal={setShowModalRegistro} idEvento={props.idEvento} noMostrarFecha={props.dataEvento.noMostrarEmailNiFecha} />
      <ModalRegistroInscripcionPresencial mostrarModal={showModalRegistroPresencial} setMostrarModal={setShowModalRegistroPresencial} year={year} month={monthName} day={day} nombree={props.dataEvento.nombre} lugare={props.dataEvento.lugarEvento} noMostrarFecha={props.dataEvento.noMostrarEmailNiFecha}  valorEmail={valorEmail} idEvento={props.idEvento} />
      <div className={`baseModal modalRegistroInscripcion ${props.mostrarModalX ? 'open' : ''}`}>
          
        <div className="modalBody modalXbody">
          <p className="modalLoginInscripcion_cerrar" onClick={() => props.closeModal(false)}>cerrar x</p>
          <div className='modalHeader'>
            {props.dataEvento.noMostrarEmailNiFecha === false &&
            <div className='fecha'>
              <div className="mes">{monthName}</div>
              <div className="dia">{day}</div>
              <div className="anyo">{year}</div>
            </div>
            }
            <div className='info'>
              <div className="titulo">{props.dataEvento.nombre}</div>
              <div className="subtitulo">{props.dataEvento.lugarEvento}</div>
            </div>
          </div>
          <div className="modalXbody_logo">
            
          </div>
          <div className="row modalX" >
            <div className="titulo">Introduce tu email profesional</div>
            <div>
              {formIsInvalid && <div className="mensajeError" dangerouslySetInnerHTML={{ __html: messageLogin }} />}
              <label className="labelInput">Email profesional</label>
              <input className={errorMail || errorExisteMail ? "inputText inputMail hasError" : "inputText inputMail"} type="email" value={valorEmail} onChange={emailChange}></input>
              {errorMail && <div className="mensajeError">Email incorrecto</div>}
             <button className="boton_registrarse" onClick={() => validaPasoUno()}>Siguiente</button>
            </div>
          </div >
        </div>
      </div>
    </Fragment>
  )
}