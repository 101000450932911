import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Canal } from "./canal";
import { ListaCanales } from "./listaCanales";
import { Home } from './home';
import { HomeEventos } from './homeEventos';
import { EventosSesiones } from './eventosSesiones';
import { EventosCiudades } from './eventosCiudades';
import { EventoPresencial } from './eventoPresencial';
import { HtmlEstatico } from './htmlEstatico';
import { StandVirtual } from './standVirtual';
import { Contenidos } from './contenidos';
import { Evento } from './evento';
import { Mybusiness } from './mybusiness';
import { HomeRegistrado } from './homeRegistrado';
import { DesactivarCuentaPermanente } from './desactivarcuentaPermanente';
import { DesactivarCuentaTemporal } from './desactivarcuentaTemporal';
import { CuentaUsuario } from './usuario/cuentaUsuario';
import { DatosProfesionales } from './usuario/datosprofesionales';
import { CambiarContrasena } from './usuario/cambiarContrasena';
import { Intereses } from './usuario/intereses';
import { Notfound } from './notfound';
import { Activarcuenta } from "./activarcuenta";
import { Activarcuenta_btnIncribirse } from "./activarcuenta_btnIncribirse";
import { LoginEvento } from "./loginEvento";
import { Recuperarpass } from './recuperarpass';

export const CargaPagina = (props) => {

    //https://businessplus.spinmedia.es/api/formatos/UrlAmbigablePublica

    console.log("en app:: cargapagina")
    const [hasErrorURL, setHasErrorURL] = useState(false)
    let formato = useParams();
    let id = useParams();

    console.log('en app:: cargapagina formato', formato)
    console.log('en app:: cargapagina eslogin', props.isAuth)

    const [dataFormato, setdataFormato] = useState(false)

    const getdataFormato = async () => {
        console.log('en app:: cargapagina getdataFormato')
        setHasErrorURL(false)
        let urlamigable = props.isAuth ? urlamigable = '/home-privada/' : urlamigable = '/home-publica/'
        if (formato[0] === undefined || formato[0] == '/' || formato[0] == '') {
            props.isAuth ? urlamigable = '/home-privada/' : urlamigable = '/home-publica/'
        } else {
            urlamigable = `/${formato[0]}`
        }
        if (!urlamigable.endsWith('/')) { urlamigable = urlamigable += '/' }

        let dataTipo = {}
        let axiosConfig = {}
        let url = `${process.env.REACT_APP_URL_API}formatos/UrlAmbigablePublica`
        console.log('fisrt url:::', url)

        if (props.isAuth) {
            url = `${process.env.REACT_APP_URL_API}formatos/UrlAmbigable`
            dataTipo = {
                "urlAmigable": urlamigable,
                "idUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-idu`),
                "idPlataforma": 1
            }
            axiosConfig = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
                },
            };
        } else {
            dataTipo = {
                "urlAmigable": urlamigable,
                "idPlataforma": 1

            }

            axiosConfig = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            };
        }
        console.log('AXIOS:: URL::2:', url)
        console.log('AXIOS:: DATA::2:', dataTipo)
        console.log('AXIOS:: CONFIG::2:', axiosConfig)

        axios.post(url, dataTipo, axiosConfig)
            .then((res) => {
                console.log('AXIOS:: DATA dataFormato::2:', res.data.data)
                setdataFormato(res.data.data)


            })
            .catch((err) => {
                console.log('AXIOS:: ERROR:2:', err)
                console.log('AXIOS:: ERROR:2:', err.response)
                console.log('AXIOS:: ERROR:2:', err.message)
                setHasErrorURL(true)
            })
    }

    useEffect(() => {
        getdataFormato();
    }, [formato[0]])

    if (hasErrorURL) {
        return (<Notfound url={formato[0]} txt="no encontrado 1" seoContenido={dataFormato.seoContenido} />)
    }
    if (dataFormato !== false) {
        console.log('platilla::', dataFormato.plantillaFrontal)
        switch (dataFormato.plantillaFrontal) {
            case 'canales':
                console.log('entra')
                return (<ListaCanales zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            case 'canal':
                console.log('entra')
                return (<Canal zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            case 'home':
                if (props.isAuth) {
                    return (<HomeRegistrado  isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                } else {
                    return (<Home  isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                }
                break
            case 'homeeventos':
                return (<HomeEventos isAuth={props.isAuth}  zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            case 'eventossesiones':
                return (<EventosSesiones isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            case 'eventosciudades':
                return (<EventosCiudades isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            case 'eventopresencial':
                return (<EventoPresencial isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} eventoSeleccionado={dataFormato.eventoSeleccionado[0]} />)
                break
            case 'contenidos':
                return (<Contenidos isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            case 'evento':
                return (<Evento isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} eventoSeleccionado={dataFormato.eventoSeleccionado[0]} />)
                break
            case 'htmlestatico':
                return (<HtmlEstatico isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            case 'standvirtual':
                return (<StandVirtual isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            case 'activarcuenta':
                return (<Activarcuenta isAuth={props.isAuth} />)
                break
            case 'activarcuenta_btnIncribirse':
                return (<Activarcuenta_btnIncribirse isAuth={props.isAuth} />)
                break
            case 'loginEvento':
                return (<LoginEvento isAuth={props.isAuth} />)
                break
            case 'recuperarcuenta':
                return (<Recuperarpass isAuth={props.isAuth} />)
                break
            case 'desactivarcuentaPermanente':
                return (<DesactivarCuentaPermanente isAuth={props.isAuth} />)
                break
            case 'desactivarcuentaTemporal':
                return (<DesactivarCuentaTemporal isAuth={props.isAuth} />)
                break
            case 'myBusiness':
                return (<Mybusiness isAuth={props.isAuth} zonasContenido={dataFormato.zonasContenido} seoContenido={dataFormato.seoContenido} />)
                break
            default:
                return (<Notfound url={formato[0]} txt="no encontrado" seoContenido={dataFormato.seoContenido} />)
                break
        }
    } else {
        return (<div></div>)
    }

}