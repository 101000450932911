import React from 'react';

import './estilos.css';

export const ModalTerminos = (props) => {
    return (
        <div className="modalterminos" style={{ display: props.show }} onClick={() => props.setShowTextoLegal("none")}>
            <div className="modalterminos_body">
                <div dangerouslySetInnerHTML={{ __html: props.textoLegal }} ></div>
            </div>
        </div>
    )
}