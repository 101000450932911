import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import Context from './Context'
import { Home } from "./paginas/home";
import { Contacto } from "./paginas/contacto";
import { HomeRegistrado } from "./paginas/homeRegistrado";
import { HomeEventos } from "./paginas/homeEventos";
import { EventosCiudades } from "./paginas/eventosCiudades";
import { EventosSesiones } from "./paginas/eventosSesiones";
import { EventoPresencial } from "./paginas/eventoPresencial";
import { ListaCanales } from "./paginas/listaCanales";
import { Canal } from "./paginas/canal";
import { Evento } from "./paginas/evento";
import { Activarcuenta } from "./paginas/activarcuenta";
import { LoginEvento } from "./paginas/loginEvento";
import { Activarcuenta_btnIncribirse } from "./paginas/activarcuenta_btnIncribirse";
import { Recuperarpass } from './paginas/recuperarpass';
import { DesactivarCuentaPermanente } from './paginas/desactivarcuentaPermanente';
import { DesactivarCuentaTemporal } from './paginas/desactivarcuentaTemporal';
import { CuentaUsuario } from "./paginas/usuario/cuentaUsuario";
import { DatosProfesionales } from './paginas/usuario/datosprofesionales';
import { MisInscripciones } from './paginas/usuario/misInscripciones';
import { CambiarContrasena } from './paginas/usuario/cambiarContrasena';
import { Intereses } from './paginas/usuario/intereses';
import { Botonbase } from './componentes/botonbase'
import { CargaPagina } from "./paginas/cargaPagina.js";
import { ModalCookie } from "./componentes/modalCookie";
import { ModalActivacion } from "./componentes/modalActivacion";
import GuardarLogContext from './ContextLog';


export const App = (props) => {
  
  const [idEvento, setIdEvento] = useState(null);
  const [logEmail, setLogEmail] = useState(false);
  const log = useContext(GuardarLogContext);
  function log_login() {
    if (logEmail) {
      log.logEvento(idEvento, false, "loginEventoMail", true);
    }
    else return
  }
  useEffect(() => {
    log_login();
  }, [])

  const [msgActivacion1, setMsgActivacion1] = useState('¡Enhorabuena ya eres de Business+!');
  const [msgActivacion2, setMsgActivacion2] = useState('Ya puedes disfrutar de todos nuestros eventos, webinars y contenido en exclusiva.');
  const [msgActivacion3, setMsgActivacion3] = useState(false);
  const [mostrarActivacion, setMostrarActivacion] = useState(false);
  console.log("mostrarActivacion", mostrarActivacion);

  if (localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-loginTime`)) {
    let actualTime = new Date().getTime();
    if (localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-limitTime`) < actualTime) {
      console.log("EL TOKEN HA CADUCADO");
      localStorage.clear();
      window.location.reload();
    }
  }
  const [dataSEO, setDataSEO] = useState(false)
  const loadSEOData = async () => {

    let axiosConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };

    axios.get(`${process.env.REACT_APP_URL_API}plataformas/1`, axiosConfig)
      .then((res) => {
        //console.log('DATA SEO 1::',res.data.data)
        setDataSEO(res.data.data)
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  useEffect(() => {
    loadSEOData();
  }, [])

  if (!dataSEO) { console.log('en app:: no content'); return (<div></div>) }

  return <Fragment> {console.log('en app:: content')}
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content={dataSEO.seoDescription} />
      <title>{dataSEO.seoTitle}</title>
      <meta property="og:title" content={dataSEO.seoTitle} />
      <meta property="og:description" content={dataSEO.seoDescription} />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content="Business+"></meta>
      <meta property="article:modified_time" content={dataSEO.fechaModificacion}></meta>
      <meta property="og:image" content={dataSEO.seoImage} />
      <meta property="og:image:width" content={dataSEO.seoImageWidth} />
      <meta property="og:image:height" content={dataSEO.seoImageHeight} />
      <meta name="twitter:title" content={dataSEO.seoTitle} />
      <meta name="twitter:description" content={dataSEO.seoDescription} />
      <meta name="twitter:image" content={dataSEO.seoImage} />
    </Helmet>
    <BrowserRouter basename={process.env.REACT_APP_EVENT_PATH} preserverScrollPosition={false}>
      <Context.Consumer>
        {
          ({ isAuth, removeAuth, logEvento }) =>
            isAuth ?
              <Switch>
                <Route path='/perfil' exact render={() => (<CuentaUsuario removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/perfil/datosprofesionales' exact render={() => (<DatosProfesionales removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/perfil/cambiarcontrasena' exact render={() => (<CambiarContrasena removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/perfil/intereses' exact render={() => (<Intereses removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/perfil/misinscripciones' exact render={() => (<MisInscripciones removeAuth={removeAuth} logEvento={logEvento} />)} />
                {/*<Route path='/homeeventos' exact render={() => (<HomeEventos removeAuth={removeAuth} logEvento={logEvento} />)} /> */}
                {/*<Route path='/eventosciudades' exact render={() => (<EventosCiudades removeAuth={removeAuth} logEvento={logEvento} />)} /> */}
                {/*<Route path='/eventossesiones' exact render={() => (<EventosSesiones removeAuth={removeAuth} logEvento={logEvento} />)} /> */}
                {/* <Route path='/eventopresencial' exact render={() => (<EventoPresencial removeAuth={removeAuth} logEvento={logEvento}/>)} /> */}
                <Route path='/activarcuenta' exact render={() => (<Activarcuenta setMostrarModalActivacion={setMostrarActivacion} removeAuth={removeAuth} />)} logEvento={logEvento} />
                <Route path='/activarcuenta_btnIncribirse' exact render={() => (<Activarcuenta_btnIncribirse setMostrarModalActivacion={setMostrarActivacion} removeAuth={removeAuth} setMsgActivacion1={setMsgActivacion1} setMsgActivacion2={setMsgActivacion2} setMsgActivacion3={setMsgActivacion3} logEvento={logEvento} />)} />
                <Route path='/recuperarcuenta' exact render={() => (<Recuperarpass removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/desactivarcuentaPermanente' exact render={() => (<DesactivarCuentaPermanente removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/desactivarcuentaTemporal' exact render={() => (<DesactivarCuentaTemporal removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/loginEvento' exact render={() => (<LoginEvento removeAuth={removeAuth} setIdEvento={setIdEvento} setLogEmail={setLogEmail} />)} />
                <Route path='/contacto' exact render={() => (<Contacto removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/*' exact render={() => (<CargaPagina isAuth={isAuth} removeAuth={removeAuth} />)} />
              </Switch>
              :
              <Switch>
                {/* <Route path='/homeeventos' exact render={() => (<HomeEventos removeAuth={removeAuth} logEvento={logEvento} />)} /> */}
                {/* <Route path='/eventosciudades' exact render={() => (<EventosCiudades removeAuth={removeAuth} logEvento={logEvento} />)} /> */}
                {/* <Route path='/eventossesiones' exact render={() => (<EventosSesiones removeAuth={removeAuth} logEvento={logEvento} />)} /> */}
                {/* <Route path='/eventopresencial' exact render={() => (<EventoPresencial removeAuth={removeAuth} logEvento={logEvento}/>)} /> */}
                <Route path='/activarcuenta' exact render={() => (<Activarcuenta setMostrarModalActivacion={setMostrarActivacion} removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/activarcuenta_btnIncribirse' exact render={() => (<Activarcuenta_btnIncribirse setMostrarModalActivacion={setMostrarActivacion} removeAuth={removeAuth} setMsgActivacion1={setMsgActivacion1} setMsgActivacion2={setMsgActivacion2} setMsgActivacion3={setMsgActivacion3} logEvento={logEvento} />)} />
                <Route path='/recuperarcuenta' exact render={() => (<Recuperarpass removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/desactivarcuentaPermanente' exact render={() => (<DesactivarCuentaPermanente removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/desactivarcuentaTemporal' exact render={() => (<DesactivarCuentaTemporal removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/loginEvento' exact render={() => (<LoginEvento removeAuth={removeAuth} logEvento={logEvento} setIdEvento={setIdEvento} setLogEmail={setLogEmail} />)} />
                <Route path='/contacto' exact render={() => (<Contacto removeAuth={removeAuth} logEvento={logEvento} />)} />
                <Route path='/*' exact render={() => (<CargaPagina isAuth={isAuth} removeAuth={removeAuth} logEvento={logEvento} />)} />
              </Switch>
        }
      </Context.Consumer>
    </BrowserRouter>
    <ModalCookie />
    <ModalActivacion mostrarModalActivacion={mostrarActivacion} setMostrarModalActivacion={setMostrarActivacion} msgActivacion1={msgActivacion1} msgActivacion2={msgActivacion2} msgActivacion3={msgActivacion3} />
  </Fragment >
}