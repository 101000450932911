import React, { useState, useRef, Fragment } from 'react';
import axios from 'axios';
import './estilos.css';
import Context from '../../Context'
import { ModalRegistroPresencial } from "../modalRegistroPresencial";
import { ModalTerminos } from "../modalTerminos";
// import { ModalLoginEvento } from "../modalLoginEvento";
/*
    reemplazado ModalLoginEvento por ModalInscripcionPresencial
    <ModalLoginEvento mostrarModalX={mostrarModalX} toggleModalLogin={toggleModalLogin} toggleModalRegisitro={toggleModalRegisitro} activateAuth={activateAuth} closeModal={setMostrarModalX} idEvento={props.idEvento} />

*/
import { ModalInscripcionPresencial } from "../modalInscripcionPresencial";


export const RegistroPresencial = (props) => {
    const terminosRef = useRef();
    const [errorAviso, setErrorAviso] = useState(false)
    const [mostrarModal, setmostrarModal] = useState(false)
    const [mostrarModalX, setMostrarModalX] = useState(false)
    const [textoLegal, setTextoLegal] = useState('')
    const [showTextoLegal, setShowTextoLegal] = useState("none")

    const HandleClick = () => {
        let formHasError = false
        console.log('clickregisto')
        if (!terminosRef.current.checked) {
            formHasError = true
            setErrorAviso(true)
        } else {
            setErrorAviso(false)
        }
        !formHasError && inscribirseEventoPresencial()
    }
    const HandleClick2 = () => {
        let formHasError = false
        console.log('clickregisto2')
        if (!terminosRef.current.checked) {
            formHasError = true
            setErrorAviso(true)
        } if (terminosRef.current.checked) {
            setErrorAviso(false)
            setMostrarModalX(true)
            console.log(mostrarModalX);
        }

    }

    const inscribirseEventoPresencial = () => {
        let dataForm = {
            "claveUsuario": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-id`),
            "idEvento": props.idEvento,
            "esPresencial": props.esPresencial,
            "codInvitacion": localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`),
        }
        let axiosConfig = {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-token`)}`
            },
        };
        console.log('dataForm 57005',dataForm)
        axios.post(`${process.env.REACT_APP_URL_API}Eventos/InscribirEvento`, dataForm, axiosConfig)
            .then((res) => {
                if (localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`)) {
                    props.logEvento(props.idEvento, false, "inscritoPorInvitacion", localStorage.getItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`))
                    localStorage.removeItem(`${process.env.REACT_APP_ID_STORAGE}-codeInvitacion`);
                }
                console.log("res: ", res);
                setmostrarModal(true)
                props.setInscrito(true)
            })
            .catch((err) => {
                console.log("err: ", err);
            })
    }
    const openTextoLegal = () => {
        fetchTextoLegal();
        setShowTextoLegal("flex")
    }

    const fetchTextoLegal = async () => {
        let dataForm = {
            "idEvento": props.idEvento,
            "idPlataforma": "1",
        }

        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        };
        axios.post(`${process.env.REACT_APP_URL_API}formatos/ObtenerTextoLegal`, dataForm, axiosConfig)
            .then((res) => {
                setTextoLegal(res.data.data);
            })
            .catch((err) => {

            })
    }

    return (
        <Context.Consumer>{({ modalRegistro, toggleModalRegisitro, modalLogin, toggleModalLogin, activateAuth, isAuth, updateEmailLogin, emailLogin }) => <>
            <ModalInscripcionPresencial updateEmailLogin={updateEmailLogin} emailLogin={emailLogin}  dataEvento={props.dataEvento} mostrarModalX={mostrarModalX} toggleModalLogin={toggleModalLogin} toggleModalRegisitro={toggleModalRegisitro} activateAuth={activateAuth} closeModal={setMostrarModalX} idEvento={props.idEvento} />
            <div className="registro-presencial">
                <ModalRegistroPresencial mostrarModal={mostrarModal} setmostrarModal={setmostrarModal} />
                <ModalTerminos textoLegal={textoLegal} show={showTextoLegal} setShowTextoLegal={setShowTextoLegal} />
                {
                    props.isAuth ?
                        !props.inscrito ?
                            <form onSubmit={(e) => { HandleClick(); e.preventDefault(); }} autocomplete="off">
                                <button className="btn_quieroinscribirme" type="submit" >Quiero inscribirme</button>
                                <div className="form-aviso">
                                    <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={terminosRef} />
                                    <label className="small"><span className="form-aviso_span_acepto">Acepto</span> el uso de mis datos facilitados para inscribirme a este evento, tanto por el Organizador como por el/los Patrocinador/es, en caso de existir, para la recepción de newsletter, promociones, remisión de invitaciones, comunicaciones comerciales (incluso por medios electrónicos), conforme se establece en los <span className="form-aviso_span" onClick={() => openTextoLegal()}>términos legales y sobre tratamiento de datos.</span></label>
                                    {errorAviso && <div className="mensajeError">Debes aceptar los términos legales</div>}
                                </div>
                            </form>
                            :
                            <div className="registered_buttons">
                                <div>Inscripción Realizada</div>
                                {/* <button><span class="material-icons-outlined">calendar_today</span>AÑADIR A MI AGENDA</button>
                                <button><span class="material-icons-outlined">email</span>REENVIAR EMAIL</button> */}
                            </div>
                        :
                        <form onSubmit={(e) => { HandleClick2(); e.preventDefault(); }} autocomplete="off">
                            <button className="btn_quieroinscribirme" type="submit" >Quiero inscribirme</button>
                            <div className="form-aviso">
                                <input type="checkbox" className={errorAviso ? "hasErrorCheck" : ""} ref={terminosRef} />
                                <label className="small"><span className="form-aviso_span_acepto">Acepto</span> el uso de mis datos facilitados para inscribirme a este evento, tanto por el Organizador como por el/los Patrocinador/es, en caso de existir, para la recepción de newsletter, promociones, remisión de invitaciones, comunicaciones comerciales (incluso por medios electrónicos), conforme se establece en los <span className="form-aviso_span" onClick={() => openTextoLegal()}>términos legales y sobre tratamiento de datos.</span></label>
                                {errorAviso && <div className="mensajeError">Debes aceptar los términos legales</div>}
                            </div>
                        </form>
                }
            </div>
        </>}
        </Context.Consumer>
    )
}