import React, { useState, useRef, useEffect, Fragment } from 'react';
import axios from 'axios';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Botonbase } from "../botonbase";
import { SelectorInput } from "../selectorInput";
import Swal from 'sweetalert2';
import './estilos.css';

export const ModalRegistro = (props) => {
  const [formIsInvalid, setformIsInvalid] = useState(false);
  const [messageLogin, setMessageLogin] = useState('Revise el formulario');


  const messageLoginChange = (val) => {
    setMessageLogin(val)
  }

  const [quitarPaso, setQuitarPaso] = useState(false);

  
  // Nombre
  const [errorNombre, setErrorNombre] = useState(false)
  const [valorNombre, setValorNombre] = useState('');
  const nombreChange = (val) => {
      setValorNombre(val.target.value)
      setErrorNombre(false)
  }

  // Apellidos
  const [errorApellidos, setErrorapellidos] = useState(false)
  const [valorApellidos, setValorApellidos] = useState('');
  const apellidosChange = (val) => {
      setValorApellidos(val.target.value)
      setErrorapellidos(false)
  }

  // Contraseña y confirmación de contraseña
  const [errorPass, setErrorPass] = useState(false)
  const [valorPass, setValorPass] = useState('');
  const passChange = (val) => {
    setValorPass(val.target.value)
    setErrorPass(false)
    
  }
  const [errorPass2, setErrorPass2] = useState(false)
  const [valorPass2, setValorPass2] = useState('');
  const passChange2 = (val) => {
    setValorPass2(val.target.value)
    setErrorPass2(false)
  }
  const [showPass, setshowPass] = useState(false);
  function showPassword() {
    if (showPass) {
      setshowPass(false)
    } else {
      setshowPass(true)
    }
  }
  const [showPass2, setshowPass2] = useState(false);
  function showPassword2() {
    if (showPass) {
      setshowPass2(false)
    } else {
      setshowPass2(true)
    }
  }

  // Sexo
  const sexoHRef = useRef();
  const sexoMRef = useRef();
  const [errorSexo, setErrorSexo] = useState(false)
  const [valorSexo, setValorSexo] = useState('');
  function handleSexo() {
    if(sexoHRef.current.checked){
     setValorSexo(sexoHRef.current.value)
    }
    if(sexoMRef.current.checked){
     setValorSexo(sexoMRef.current.value)
    }
  }

  // DNI
  const [errorDni, setErrorDni] = useState(false)
  const [valorDNI, setValorDNI] = useState('');
  const dniChange = (val) => {
    setValorDNI(val.target.value)
    setErrorDni(false)
  }

  // Edad
  const [errorEdad, setErrorEdad] = useState(false)
  const [valorEdad, setValorEdad] = useState('Edad');
  
  // Nivel de formación
  const formacionEGBRef = useRef();
  const formacionESORef = useRef();
  const formacionFPRef = useRef();
  const formacionUNIRef = useRef();
  const [errorFormacion, setErrorFormacion] = useState(false)
  const [valorFormacion, setValorFormacion] = useState('');
  function handleFormacion() {

    if(formacionEGBRef.current.checked){
      setValorFormacion(formacionEGBRef.current.value)
    }
    if(formacionESORef.current.checked){
      setValorFormacion(formacionESORef.current.value)
    }
    if(formacionFPRef.current.checked){
      setValorFormacion(formacionFPRef.current.value)
    }
    if(formacionUNIRef.current.checked){
      setValorFormacion(formacionUNIRef.current.value)
    }
  }

  // Sector 
  const sectorAgriculturaRef = useRef();
  const sectorConstruccionRef = useRef();
  const sectorIndustriaRef = useRef();
  const sectorServiciosRef = useRef();
  const sectorHosteleriaRef = useRef();
  const sectorComercioRef = useRef();
  const [errorSector, setErrorSector] = useState(false)
  const [valorSector, setValorSector] = useState('');
  function handleSector() {
    
    if(sectorAgriculturaRef.current.checked){
      setValorSector(sectorAgriculturaRef.current.value)
    }
    if(sectorConstruccionRef.current.checked){
      setValorSector(sectorConstruccionRef.current.value)
    }
    if(sectorIndustriaRef.current.checked){
      setValorSector(sectorIndustriaRef.current.value)
    }
    if(sectorServiciosRef.current.checked){
      setValorSector(sectorServiciosRef.current.value)
    }
    if(sectorHosteleriaRef.current.checked){
      setValorSector(sectorHosteleriaRef.current.value)
    }
    if(sectorComercioRef.current.checked){
      setValorSector(sectorComercioRef.current.value)
    }
   }

  // Provincia
  const [errorProvincia, setErrorProvincia] = useState(false)
  const [valorProvincia, setValorProvincia] = useState('Provincia');

  // Localidad
  const [errorLocalidad, setErrorLocalidad] = useState(false)
  const [valorLocalidad, setValorLocalidad] = useState('');
  const localidadChange = (val) => {
      setValorLocalidad(val.target.value)
      setErrorLocalidad(false)
  }

  //Cómo nos has conocido
  const conocidoEmailRef = useRef();
  const conocidoWebRef = useRef();
  const conocidoRedesRef = useRef();
  const conocidoOtrosRef = useRef();
  const [errorConocido, setErrorConocido] = useState(false)
  const [valorConocido, setValorConocido] = useState('');
  function handleConocido() {
    
    if(conocidoEmailRef.current.checked){
      setValorConocido(conocidoEmailRef.current.value)
    }
    if(conocidoWebRef.current.checked){
      setValorConocido(conocidoWebRef.current.value)
    }
    if(conocidoRedesRef.current.checked){
      setValorConocido(conocidoRedesRef.current.value)
    }
    if(conocidoOtrosRef.current.checked){
      setValorConocido(conocidoOtrosRef.current.value)
    }
  }

  // Teléfono
  const [errorTelefono, setErrorTelefono] = useState(false)
  const [valorTelefono, setValorTelefono] = useState('');
  const telefonoChange = (val) => {
    setValorTelefono(val.target.value)
    setErrorTelefono(false)
  }

  // Email
  const [errorMail, setErrorMail] = useState(false);
  const [errorExisteMail, setErrorExisteMail] = useState(false);
  const [existeMail, setExisteMail] = useState();
  const [valorEmail, setValorEmail] = useState('');
  const emailChange = (val) => {
    setValorEmail(val.target.value)
    setErrorMail(false)
    setErrorExisteMail(false)
  }

  // Situación Laboral
  const laboralautonomoRef = useRef();
  const laboralEmpleadoRef = useRef();
  const laboralDesempleadoRef = useRef();
  const [errorLaboral, setErrorLaboral] = useState(false)
  const [valorLaboral, setValorLaboral] = useState('');
  function handleLaboral() {
    
    if(laboralautonomoRef.current.checked){
      setValorLaboral(laboralautonomoRef.current.value)
    }
    if(laboralEmpleadoRef.current.checked){
      setValorLaboral(laboralEmpleadoRef.current.value)
    }
    if(laboralDesempleadoRef.current.checked){
      setValorLaboral(laboralDesempleadoRef.current.value)
    }
  }

  // Veracidad
  const [errorVeracidad, setErrorVeracidad] = useState(false)
  const [valorVeracidad, setValorVeracidad] = useState('');
  const veracidadRef = useRef();
  function handleVeracidad() {

    if(veracidadRef.current.checked){
      setValorVeracidad(veracidadRef.current.value)
    }
  
  }

  // Privacidad
  const [errorPrivacidad, setErrorPrivacidad] = useState(false)
  const [valorPrivacidad, setValorPrivacidad] = useState('');
  const privacidadRef = useRef();
  function handlePrivacidad() {
    
    if(privacidadRef.current.checked){
      setValorPrivacidad(privacidadRef.current.value)
    }
  
  }
  
  const limpiaFormulario = () => {
    setValorNombre('')
    setErrorNombre(false)
    setValorApellidos('')
    setErrorapellidos(false)
    setValorPass('')
    setErrorPass(false)
    setValorPass2('')
    setErrorPass2(false)
    setValorDNI('')
    setErrorDni(false)
    setErrorEdad(false)
    setErrorFormacion(false)
    setErrorSector(false)
    setErrorProvincia(false)
    setValorLocalidad('')
    setErrorLocalidad(false)
    setErrorConocido(false)
    setValorTelefono('')
    setErrorTelefono(false)
    setValorEmail('')
    setErrorMail(false)
    setErrorExisteMail(false)
    setErrorVeracidad(false)
    setErrorPrivacidad(false)
  }

  const CerrarModal = () => {
    limpiaFormulario()
    props.toggleModalRegisitro()
  }

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
 
  const FinalizaInscripcion = async () => {
    let formHasError = false;
    const telefonoRegex = /^[0-9]*$/;
    var regex = /^(?=.*[0-9])(?=.*[!@#$%^&-_*])[a-zA-Z0-9!@#$%^&-_*]{6,16}$/;
    var regexchar = /^(?=.*[!@#$%^&-_*])[a-zA-Z0-9!@#$%^&-_*]{6,16}$/;
    //if (regex.test(valorPass) || regexchar.test(valorPass)) {
    if (valorPass !== ''){
      setErrorPass(false)
    } else {
      setErrorPass(true)
      formHasError = true
    }

    if (valorPass === valorPass2)  {
      setErrorPass2(false)
    } else {
      setErrorPass2(true)
      formHasError = true
    }
    if (valorNombre.length == 0) {
      formHasError = true
      setErrorNombre(true)
    } else {
      setErrorNombre(false)
    }
    if (valorApellidos.length == 0) {
      formHasError = true
      setErrorapellidos(true)
    } else {
      setErrorapellidos(false)
    }
    if (valorSexo === '' || valorSexo === false) {
      formHasError = true
      setErrorSexo(true)
    } else {
      setErrorSexo(false)
    }
    if (valorDNI.length != 9) {
      formHasError = true
      setErrorDni(true)
    } else {
      setErrorDni(false)
    }
    if (valorEdad === 'Edad') {
      formHasError = true
      setErrorEdad(true)
    } else {
      setErrorEdad(false)
    }
    if (valorProvincia === 'Provincia') {
      formHasError = true
      setErrorProvincia(true)
    } else {
      setErrorProvincia(false)
    }
    if (valorFormacion === '' || valorFormacion === false) {
      formHasError = true
      setErrorFormacion(true)
    } else {
      setErrorFormacion(false)
    }
    if (valorSector === '' || valorSector === false) {
      formHasError = true
      setErrorSector(true)
    } else {
      setErrorSector(false)
    }
    if (valorConocido === '' || valorConocido === false) {
      formHasError = true
      setErrorConocido(true)
    } else {
      setErrorConocido(false)
    }
    var regexemail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (regexemail.test(valorEmail) ) {
      setErrorMail(false)
      
    } else {
      
      setErrorMail(true)
      formHasError = true
    }
    if (valorLaboral === '' || valorLaboral === false) {
      formHasError = true
      setErrorLaboral(true)
    } else {
      setErrorLaboral(false)
    }
    if (valorVeracidad === '' || valorVeracidad === false) {
      formHasError = true
      setErrorVeracidad(true)
    } else {
      setErrorVeracidad(false)
    }
    if (valorPrivacidad === '' || valorPrivacidad === false) {
      formHasError = true
      setErrorPrivacidad(true)
    } else {
      setErrorPrivacidad(false)
    }
    
    if (valorTelefono.length <= 7 || valorTelefono.length >= 14 || !telefonoRegex.test(valorTelefono)) {
      formHasError = true
      setErrorTelefono(true)
    } else {
      setErrorTelefono(false)
    }
    if( formHasError){
      setformIsInvalid(true)
      return
    }else{
      setformIsInvalid(false)
    }

    try {
      var dataForm = {
        "Usuario": titleCase(valorNombre),
        "Nombre": titleCase(valorNombre),
        "Apellidos": titleCase(valorApellidos),
        "Password": valorPass,
        "Sexo": valorSexo,
        "Dni": valorDNI,
        "Edad": valorEdad,
        "Puesto": valorFormacion,
        "Departamento": valorSector,
        "Provincia": valorProvincia,
        "Ciudad": valorLocalidad,
        "MedioEncontrado": valorConocido,
        "Telefono": valorTelefono.split(" ").join(""),
        "Email": valorEmail.toLowerCase(),
        "SituacionLaboral": valorLaboral,
       // "veracidad": valorVeracidad,
       // "privacidad": valorPrivacidad,
        "enlaceReturn": window.location.pathname
        
      }
      let axiosConfig = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      };
      console.log('Datos form::',dataForm)
      await axios.post(`${process.env.REACT_APP_URL_API}auth/RegistroWeb_UPTACadiz`, dataForm, axiosConfig)
        .then(resLogin => {
          if (resLogin.status === 200) {
            if (resLogin.data === 'error') {
              console.log(resLogin.data)
              setformIsInvalid(true)
              messageLoginChange('Email ya existen')
            } else {
              console.log('registrado')
              setformIsInvalid(false)
              setQuitarPaso(true)
              CerrarModal()
              Swal.fire({
                title: '',
                text: `Tu registro se ha realizado correctamente.`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3B619E',
                confirmButtonText: 'Aceptar',
              }).then((result) => {
                if (result.value) {

                }
              })
            }
          } else {
            console.log(resLogin)
            setformIsInvalid(true)
            messageLoginChange('Email ya existen')
          }

        })
      } catch (error) {
        // messageLoginChange('Ha habido un error, intentelo de nuevo más tarde')
        setMessageLogin(error.response.data.message)
        setformIsInvalid(true)
        console.log('ModalRegistro:: ERROR:2:', error)
        console.log('ModalRegistro:: ERROR:2:', error.response)
        console.log('ModalRegistro:: ERROR:2:', error.message)
      }
  }



  return (
    <div className={`baseModal ${props.mostrarModal ? 'open' : ''}`}>
      <div className="modalBody modalRegistro">
      <div className='formscroll'>
      <p className="modalLogin_cerrar" onClick={() => CerrarModal()}>cerrar <span>x</span></p>
        <div className="modalLogin_logo">
          <div>
            <img className='logo' src="https://uptawebinarscadiz.com/assets/images/Logo_popup.png" alt="logo" />
          </div>
          <div className='logosflex'>
            <img className='logodiptucion' src="https://uptawebinarscadiz.com/assets/images/diputacion-cadiz_popup.png" alt="diputación de Cadiz" />
            <img className='logoagnda' src="https://uptawebinarscadiz.com/assets/images/upta-agenda.png" alt="Agenda" />
          </div>
        </div>
        
          <div>
            <div className="titulo">Crear una <span>cuenta nueva</span></div>
            
           
          </div>


          <div className='form-context'>
            <div className="form-group col-2">
              <input placeholder='Nombre' className={errorNombre ? "inputText hasError" : "inputText"} type="text" value={valorNombre} onChange={nombreChange}></input>
              {errorNombre && <div className="mensajeError">Introduce un nombre válido</div>}
            </div>
            <div className="form-group col-2">
              <input placeholder='Apellidos' className={errorApellidos ? "inputText hasError" : "inputText"} type="text" value={valorApellidos} onChange={apellidosChange}></input>
              {errorApellidos && <div className="mensajeError">Introduce un apellido válido</div>}
            </div>
            <div className="form-group col-2 mt-10">
              <input placeholder='Crear contraseña' className={errorPass ? "inputText hasError" : "inputText"} type={showPass ? 'text' : 'password'} onChange={passChange}></input>
              {
                showPass ?
                  <span toggle="#password-field" class="icono-input"><AiFillEye onClick={showPassword} /></span>
                  :
                  <span toggle="#password-field" class="icono-input"><AiFillEyeInvisible onClick={showPassword} /></span>
              }
              {errorPass && <div className="mensajeError">Introduce una contraseña</div>}
            </div>
            <div className="form-group col-2 mb-10">
              <input placeholder='Repetir contraseña' className={errorPass2 ? "inputText hasError" : "inputText"} type={showPass2 ? 'text' : 'password'} onChange={passChange2}></input>
              {
                showPass ?
                  <span toggle="#password-field" class="icono-input"><AiFillEye onClick={showPassword2} /></span>
                  :
                  <span toggle="#password-field" class="icono-input"><AiFillEyeInvisible onClick={showPassword2} /></span>
              }
              {errorPass && <div className="mensajeError">Las contraseñas no coinciden</div>}
            </div>
            <div className="form-group col-2 horizontal mt-10">
              <div className='title-field'>Sexo:</div>
              <div className='inputGroup'>
                <input type="radio" id="sexo" name="sexo" value="Hombre"  ref={sexoHRef} onClick={() => handleSexo()} />
                <label for="html">Hombre</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="sexo" name="sexo" value="Mujer" ref={sexoMRef} onClick={() => handleSexo()}/>
                <label for="css">Mujer</label>
              </div>
              {errorSexo && <div className="mensajeError">Seleccione una opción</div>}
            </div>
            <div className="row">
              <div className="form-group col-1 step3_col_ancho">
                
                <input placeholder='DNI' className={errorDni ? "inputText hasError" : "inputText"} type="text" value={valorDNI} onChange={dniChange}></input>
                {errorDni && <div className="mensajeError">El CIF debe ser un texto alfanumérico de 9 caracteres, sin guiones</div>}
              </div>
            </div>
            
            <div className="form-group col-1 step3_col_ancho mb-10">
              <SelectorInput
                options={["18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", 
                "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", 
                "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70",
                "71", "72", "73", "74", "75", "76", "77", "78", "79", "80", "81", "82", "83", "84", "85", "86", "87", "88", "89", "90",
                "91", "92", "93", "94", "95", "96", "97", "98", "99"
              ]}
                id="facturacionRegistro"
                selectedValue={valorEdad}
                setSelectedValue={setValorEdad}
                error={errorEdad}
              />
              {errorEdad && <div className="mensajeError2">Selecciona tu edad</div>}
            </div>
            <div className="form-group col-2 ">
              <div className='title-field'>Nivel de formación:</div>
              <div className='inputGroup'>
                <input type="radio" id="EGB" name="formacion" value="EGB" ref={formacionEGBRef} onClick={() => handleFormacion()} />
                <label for="html">Educación primaria o secundaria (EGB o similar)</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="ESO" name="formacion" value="ESO" ref={formacionESORef} onClick={() => handleFormacion()} />
                <label for="css">Educación secundaria superior (BUP, ESO o similar)</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="FP" name="formacion" value="FP" ref={formacionFPRef} onClick={() => handleFormacion()} />
                <label for="css">Educación post secundaria no terciaria (Técnicos, FP o similar)</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="UNIVERSIDAD" name="formacion" value="UNIVERSIDAD" ref={formacionUNIRef} onClick={() => handleFormacion()} />
                <label for="css">Educación terciaria (Estudios universitarios)</label>
              </div>
              {errorFormacion && <div className="mensajeError2">Selecciona una opción</div>}
            </div>
            <div className="form-group col-2 ">
              <div className='title-field'>Sector al que pertenece (marcar)</div>
              <div className='inputGroup'>
                <input type="radio" id="Agricultura" name="sector" value="Agricultura" ref={sectorAgriculturaRef} onClick={() => handleSector()} />
                <label for="html">Agricultura</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="Construcción" name="sector" value="Construcción" ref={sectorConstruccionRef} onClick={() => handleSector()}  />
                <label for="css">Construcción</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="Industria" name="sector" value="Industria" ref={sectorIndustriaRef} onClick={() => handleSector()}  />
                <label for="css">Industria</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="Servicios" name="sector" value="Servicios" ref={sectorServiciosRef} onClick={() => handleSector()}  />
                <label for="css">Servicios</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="Hosteleria" name="sector" value="Hostelería" ref={sectorHosteleriaRef} onClick={() => handleSector()} />
                <label for="css">Hostelería</label>
              </div>
              <div className='inputGroup'>
                <input type="radio" id="Comercio" name="sector" value="Comercio" ref={sectorComercioRef} onClick={() => handleSector()} />
                <label for="css">Comercio</label>
              </div>
              {errorSector && <div className="mensajeError2">Selecciona una opción</div>}
            </div>
          </div>
          <div className="form-group col-1 step3_col_ancho mt-10">
            <SelectorInput
              options={[ "Cádiz"]}
              id="ProvinciaInput"
              selectedValue={valorProvincia}
              setSelectedValue={setValorProvincia}
              error={errorProvincia}
            />
            {errorProvincia && <div className="mensajeError2">Elige una opción</div>}
          </div> 
          <div className="form-group col-2">
            <input placeholder='Localidad' className={errorNombre ? "inputText hasError" : "inputText"} type="text" value={valorLocalidad} onChange={localidadChange}></input>
            {errorLocalidad && <div className="mensajeError">Introduce tu localidad</div>}
          </div>   
          <div className="form-group col-2 ">
            <div className='title-field'>¿Cómo nos ha conocido?</div>
            <div className='inputGroup'>
              <input type="radio" id="Email" name="conocido" value="Email" ref={conocidoEmailRef} onClick={() => handleConocido()} />
              <label for="html">Email</label>
            </div>
            <div className='inputGroup'>
              <input type="radio" id="Web" name="conocido" value="Web" ref={conocidoWebRef} onClick={() => handleConocido()}/>
              <label for="css">Web</label>
            </div>
            <div className='inputGroup'>
              <input type="radio" id="Redessociales" name="conocido" value="Redes sociales" ref={conocidoRedesRef} onClick={() => handleConocido()} />
              <label for="css">Redes sociales</label>
            </div>
            <div className='inputGroup'>
              <input type="radio" id="Otros" name="conocido" value="Otros" ref={conocidoOtrosRef} onClick={() => handleConocido()} />
              <label for="css">Otros</label>
            </div>
            {errorConocido && <div className="mensajeError2">Selecciona una opción</div>}
          </div>
          <div className="form-group col-2 mt-10">
            <input placeholder='Teléfono de contacto' className={errorTelefono ? "inputText hasError" : "inputText"} type="text"  onChange={telefonoChange} value={valorTelefono}></input>
            {errorTelefono && <div className="mensajeError">Introduce un número de teléfono válido</div>}
          </div>
          <div className='form-group col-2'>
            <input placeholder='Correo electrónico' className={errorMail || errorExisteMail ? "inputText inputMail hasError" : "inputText inputMail"} type="email" value={valorEmail} onChange={emailChange}></input>
            {errorMail && <div className="mensajeError">Email incorrecto</div>}
            {errorExisteMail && <div className="mensajeError">Email existente</div>}
          </div> 
          <div className="form-group col-2 ">
            <div className='title-field'>Situación laboral</div>
            <div className='inputGroup'>
              <input type="radio" id="Autónomoaprofesional" name="situacionlaboral" value="Autónomo/a / profesional" ref={laboralautonomoRef} onClick={() => handleLaboral()} />
              <label for="html">Autónomo/a / profesional</label>
            </div>
            <div className='inputGroup'>
              <input type="radio" id="Empleadoaporcuentaajena" name="situacionlaboral" value="Empleado/a por cuenta ajena"  ref={laboralEmpleadoRef} onClick={() => handleLaboral()} />
              <label for="css">Empleado/a por cuenta ajena</label>
            </div>
            <div className='inputGroup'>
              <input type="radio" id="Desempleadoa" name="situacionlaboral" value="Desempleado/a"  ref={laboralDesempleadoRef} onClick={() => handleLaboral()} />
              <label for="css">Desempleado/a</label>
            </div>
            {errorLaboral && <div className="mensajeError2">Selecciona una opción</div>}
          </div>
          <div className="form-group col-2 ">
            <div className='title-field'>Declaro la veracidad de los datos reflejados</div>
            <div className='inputGroup'>
              <input type="radio" id="Si" name="veracidad" value="Sí" ref={veracidadRef} onClick={() => handleVeracidad()}  />
              <label for="html">Sí</label>
            </div>
            {errorVeracidad && <div className="mensajeError2">Debe aceptar la declaración de veracidad</div>}
          </div>
          <div className="form-group col-2 ">
            <div className='title-field'>Acepto la <a href="/politica-de-privacidad/" target="_blank">política de privacidad</a></div>
            <div className='inputGroup'>
              <input type="radio" id="Siprivacidad" name="privacidad" value="Sí" ref={privacidadRef} onClick={() => handlePrivacidad()} />
              <label for="html">Sí, acepto</label>
            </div>
            {errorPrivacidad && <div className="mensajeError2">Debe aceptar la política de privacidad</div>}
          </div>
        
          <button className="boton_registrarse" onClick={() => FinalizaInscripcion()}>CREAR CUENTA</button>
          {formIsInvalid && <div className="mensajeError">{messageLogin}</div>}

          <div className='info-form'>
            <hr/>
            <div className='titulo-info-form'>A la mayor brevedad posible nos pondremos en contacto con usted</div>
            <div className='content-info-form'>
              En cumplimiento del Reglamento UE 2016/679 relativo a la protección de datos personales y la Ley 34/2002, de Servicios de la Sociedad de la Información y Comercio Electrónico, modificada por la Ley 32/2003, General de Telecomunicaciones, la Unión de Profesionales y Trabajadores Autónomos (UPTA), con NIF: G82875808 y domicilio en C/ Ciudad Real, 5 - local. 28045 Madrid, le informamos que tratamos la información que nos facilita con la única finalidad de gestionar su participación en proyectos y estudios de asesoramiento sobre formación y mantenerle informado/a de las acciones formativas. Los datos proporcionados se conservarán mientras no solicite su supresión, o durante los años necesarios para cumplir con las obligaciones legales. Los datos no se cederán a terceros, salvo para cumplir con las obligaciones legales. Usted tiene derecho a acceder a sus datos personales, solicitar su rectificación o supresión cuando los datos ya no sean necesarios, limitar u oponerse a su tratamiento, así como solicitar la portabilidad de los datos, en la dirección arriba indicada o en el contacto de nuestro Delegado de Protección de Datos, dpd@upta.es. También tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos. Puede ampliar esta información consultando nuestra Política de Protección de Datos en la Web: www.upta.es
            </div>
          </div>
        </div>   
      </div>
    </div>
  )
}